.project-tab {
    padding: 10%;
    margin-top: -8%;
  }
  .project-tab #tabs{
    background: #007b5e;
    color: #eee;
  }
  .project-tab #tabs h6.section-title{
    color: #eee;
  }
  .project-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #1adbdb;
    /* color: #FF9B42; */
    /* color: #F7A072; */
    /* position: absolute; */
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 3px solid !important;
    /* bottom: 40px; */
    /* bottom: -15px; */
    /* font-size: 14px; */
    font-weight: bold;
  }
  .project-tab .nav-link {
    /* border: 1px solid transparent; */
    border:none;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #0062cc;
    /* font-size: 16px; */
    font-weight: 600;
  }
  .nav-tabs .nav-link {
    /* border: 1px solid transparent; */
     /* padding-top: 20.5px;
    padding-bottom: 20.5px;  */
    
    border: none;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }
  
  .project-tab .nav-link:hover {
    border: none;
  }
  .project-tab thead{
    background: #f3f3f3;
    color: #0062cc;
  }
  .project-tab a{
    text-decoration: none;
    color: #0062cc;
    /* font-weight: 600; */
  }
  .nav-tabs{
    border-bottom:none;
  }
  a{
    text-decoration: none;
    color: white;
    /* font-weight: 600; */
    /* font-size: 14px */
  }
  /* nav{
    padding-bottom: 1px;
  } */
  