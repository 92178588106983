.Login{
  margin-top: 10%;
  padding-left: 30%;
  padding-right: 30%;

}


.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0.5px 0.5px 2px 1px rgba(0.05, 0.05, 0.05, 0.08);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
}
.google-button:hover {
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
}
.google-button  :active{
  background-color: #e5e5e5;
  box-shadow: none;
  transition-duration: 100ms;
}
/* button :active {
  background-color: white;
  box-shadow: none;
  transition-duration: 100ms; */
/* } */

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

/* // Boilerplate stuff */

/* html, body {
    height: 100%;
  }
  
  body {
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

button ~ button {
  margin-left: 20px;
}
